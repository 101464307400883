import { React } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { convertFieldToText } from '../../Helpers/SelectValues/Fields';
import { convertOperatorToText } from '../../Helpers/SelectValues/Operators';

const RuleTableRow = function (props) {
  const { rule } = props;

  return (
    <tr>
      <td>{rule.name}</td>
      <td className="d-none d-sm-table-cell">{convertFieldToText(rule.field)}</td>
      <td className="d-none d-sm-table-cell">{convertOperatorToText(rule.operator)}</td>
      <td className="d-none d-sm-table-cell">{rule.displayValue}</td>
      <td>
        <Link to={`/rules/update-rule/${rule.id}`} className="update-link">
          Update
        </Link>
      </td>
    </tr>
  );
};
RuleTableRow.propTypes = {
  rule: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    operator: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    displayValue: PropTypes.string.isRequired,
  }).isRequired,
};

export default RuleTableRow;
