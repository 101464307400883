import { React, useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import RuleTableRow from './RuleTableRow';
import { getAllRulesAsync } from '../../Helpers/API/RuleApi';

const RuleList = function () {
  const [rules, setRules] = useState([]);

  useEffect(() => {
    async function getRules() {
      const data = await getAllRulesAsync();
      setRules(data);
    }
    getRules();
  }, []);

  const RulesTable = () => rules.map(
    (rule) => <RuleTableRow rule={rule} key={rule.id} />,
  );

  return (
    <div>
      <div className="breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Rules</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <h4>Rules</h4>
      <div className="table-wrapper">
        <Link to="/rules/create-rule/" className="header-link btn">
          Create a new rule
        </Link>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th className="d-none d-sm-table-cell">Field</th>
              <th className="d-none d-sm-table-cell">Operator</th>
              <th className="d-none d-sm-table-cell">Value</th>
              <th className="grid-actions-header-small">&nbsp;</th>
            </tr>
          </thead>
          <tbody>{RulesTable()}</tbody>
        </Table>
      </div>
    </div>

  );
};

export default RuleList;
